import { defineComponent } from 'vue'

import type { PropType } from 'vue'

export type ToggleValueTypes = number | string

export default defineComponent({
  props: {
    modelValue: {
      type: [Array, Boolean] as PropType<ToggleValueTypes[] | boolean>,
      required: true,
    },
    valueName: {
      type: [String, Number] as PropType<ToggleValueTypes | undefined>,
      required: false,
      default: undefined,
    },
    invert: {
      default: false,
      type: Boolean,
    },
    indeterminate: {
      default() {
        return false
      },
      required: false,
      type: Boolean,
    },
    disabled: {
      default() {
        return false
      },
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    innerValue(): boolean {
      if (this.valueIsBoolean(this.modelValue))
        return this.invert ? !this.modelValue : this.modelValue
      else if (this.valueName)
        return this.invert
          ? !this.modelValue.includes(this.valueName)
          : this.modelValue.includes(this.valueName)
      else return false
    },
  },
  methods: {
    valueIsBoolean(value: ToggleValueTypes[] | boolean): value is boolean {
      return typeof value === 'boolean'
    },
  },
})
