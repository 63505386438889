<template>
  <div
    class="tw-flex tw-cursor-pointer tw-justify-start"
    role="checkbox"
    :aria-checked="ariaChecked"
    @click="toggle"
  >
    <div
      class="checkboxElem tw-flex tw-h-5 tw-min-h-[20px] tw-w-5 tw-min-w-[20px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-sm tw-border tw-border-solid tw-border-gray-400 tw-bg-white tw-transition-colors tw-duration-150 tw-ease-in-out focus:tw-outline-none focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-1 focus-visible:tw-outline-black"
      :class="boxClass"
      tabindex="0"
      @blur="$emit('blur', $event)"
      @keyup.enter="toggle"
    >
      <transition name="scale">
        <i v-if="indeterminate" class="fas fa-minus tw-text-xs tw-text-white" />
        <i
          v-else-if="innerValue"
          class="fas fa-check tw-text-xs tw-text-white"
        />
      </transition>
    </div>
    <div v-if="label !== undefined" class="checkboxLabel" :class="labelClass">
      {{ label }}
    </div>
    <div v-else-if="$slots.label" class="checkboxLabel" :class="labelClass">
      <slot name="label" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import InputToggleMixin from '~/mixins/inputs/toggles'
import validationChildMixin from '~/mixins/validationChild'

import type { ToggleValueTypes } from '~/mixins/inputs/toggles'

export default defineComponent({
  mixins: [InputToggleMixin, validationChildMixin],
  props: {
    indeterminate: {
      type: Boolean,
      required: false,
      default: false,
    },
    softDisabled: {
      default() {
        return false
      },
      type: Boolean,
    },
    label: {
      required: false,
      type: [String, Number],
      default: undefined,
    },
    flipDisplay: {
      default: () => false,
      type: Boolean,
    },
  },
  emits: {
    // TODO TYPE - This could be refined using component generics in the composition API
    'update:modelValue': (payload: ToggleValueTypes[] | boolean) => true,
    blur: (payload: Event) => true,
    change: (payload: void) => true,
  },
  computed: {
    boxClass(): Record<string, boolean> {
      return {
        disabled: this.disabled,
        checked:
          this.indeterminate ||
          (this.flipDisplay ? !this.innerValue : this.innerValue),
        error: !this.validity,
      }
    },
    labelClass(): Record<string, boolean> {
      return {
        disabled: this.disabled,
        error: !this.validity,
      }
    },
    ariaChecked(): boolean | 'mixed' {
      if (this.indeterminate) return 'mixed'

      return this.innerValue
    },
  },
  methods: {
    toggle(): void {
      if (!this.disabled || !this.softDisabled) {
        this.$emit('change')

        if (typeof this.modelValue === 'boolean')
          this.$emit('update:modelValue', !this.modelValue)
        else if (this.valueName) {
          const index = this.modelValue.indexOf(this.valueName)
          const newarr = [...this.modelValue]

          if (index !== -1) newarr.splice(index, 1)
          else newarr.push(this.valueName)

          this.$emit('update:modelValue', newarr)
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.checkboxElem {
  &.checked {
    @apply tw-border-orange-500 tw-bg-orange-500;
  }

  &.error {
    @apply tw-border-2 tw-border-error;
  }

  &disabled {
    @apply tw-pointer-events-none tw-opacity-50;
  }
}

.checkboxLabel {
  @apply tw-ml-3 tw-text-base tw-text-black tw-transition-colors tw-duration-150 tw-ease-in-out;

  &.disabled {
    @apply tw-opacity-50;
  }

  &.error {
    @apply tw-text-error;
  }
}
</style>
